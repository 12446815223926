import React from "react";
import s from "./News.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import arr from "../../assets/arrow.svg"
import YouTube from "react-youtube";
import {Hrefs} from "../common/PageInfo/PageInfoPriceLife"


const VideoPrice = ({children, img, header}) => {
    const [newsData, setNews] = useState([])
    useEffect(() => {

        axios.get("https://fcprc.ru/api/webinars").then(
            res => {
                let data = res.data.reverse()

                setNews(data)
            }
        )
    }, [])
    const newsData1 = [
        {
            header: "Территория онлайн ‒ без стресса"
            , img: "t1P3z5jwj_U"
            , ref: "/webinars/tws"
        },
        {
            header: "На экзамены – без стресса"
            , img: "fq1-KcPBcn8"
            , ref: "/webinars/exameni-bez-stressa"
        },
        {
            header: "Ограничение и наказание: эффективность в воспитании"
            , img: "HF_wi3DU658"
            , ref: "/webinars/ogranichenie-i-nakazanie-effektivnost-v-vospitanii"
        },
        {
            header: "Консультирование по вопросам детско-родительских отношений, девиантного поведения несовершеннолетних"
            , img: "4sALjyZzVds"
            , ref: "/webinars/konsultirovanie-po-voprosam-detsko-roditelskih-otnoshenij-deviantnogo-povedeniya-nesovershennoletnih-sostoyalos-29-aprelya-2020-g"
        },
        {
            header: "Родительские ресурсы"
            , img: "wFaP68oOUIk"
            , ref: "/webinars/resursy-roditelya"
        },
        {
            header: "Специфика преодоления стресса в современных условиях самоизоляции"
            , img: "2u86x7vXD6o"
            , ref: "/webinars/spetsifika-preodoleniya-stressa-v-usloviyah-samoizolyatsii"
        },
        {
            header: "Психология зависимого поведения"
            , img: "eW08mNB4h10"
            , ref: "/webinars/psihologiya-zavisimogo-povedeniya"
        },
        {
            header: "8 правил безопасного общения с подростками: как родителям реагировать на «трудное» поведение ребенка"
            , img: "4kh_HfVmYoc"
            , ref: "/webinars/8-pravil-bezopasnogo-obshheniya-s-podrostkami-kak-roditelyam-reagirovat-na-trudnoe-povedenie-rebenka"
        },
        {
            header: "Особенности подросткового возраста с точки зрения психологии, социологии и этологии: подростковая девиантность"
            , img: "HhucqBtPJeg"
            , ref: "/webinars/osobennosti-podrostkovogo-vozrasta-s-tochki-zreniya-psihologii-sotsiologii-i-etologii-podrostkovaya-deviantnost"
        },

        {
            header: "Организация работы по предотвращению детской смертности"
            , img: "oaozXW2akb8"
            , ref: "/webinars/organizatsiya-raboty-po-predotvrashheniyu-detskoj-smertnosti-dlya-pedagogov-psihologov/"
        },
    ]
    const hrefsArr = [
        {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy", input: []},
        {text: "Информационно-методические материалы для специалистов", to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"},
        {
            text: "Информационные материалы для родителей и детей",
            to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
        },
        {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
        {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
    ]
    return <div className={s.newsPage} style={{fontWeight: "normal"}}>

        <div className={s.filesLeftPage}>

            <div className={s.newsListPrice}>
                <div className={s.newHeader}>
                    <h2>
                        Видеоматериалы
                    </h2>
                </div>
                {newsData1 ? newsData1.map(el => <div>
                    <NewsSquare img={el.img} header={el.header} refs={el.ref}/>
                </div>) : <div></div>}
            </div>
            <div style={{marginTop: "57px"}}>
                <Hrefs hrefsArr={hrefsArr}/>
            </div>
        </div>


    </div>
}

const NewsSquare = ({img = "", header = "", text, refs = "/"}) => {
    return <div className={s.one}>
        <div>
            <div className={s.icon}>
                <a href={refs}>
                    <YouTube style={{textAlign: "center",}} videoId={img}/>
                </a>
            </div>
        </div>
        <div style={{overflow: "hidden", height: "43px", marginTop: "15px"}}>
            <a href={refs}>
                {header}
            </a>
        </div>
        <a href={refs}>
            <button className={s.subBtn}>
                Читать далее <img src={arr} style={{width: "20px"}}/>
            </button>
        </a>
    </div>
}


export default VideoPrice