import React from "react";
import s from "./Main.module.css"
import Calendar from 'react-calendar';
import "./Calendar.css"

const CalendarPage = () => {
    return <>
        <div className={s.calFind}>
            <div>
                <h3 style={{
                    margin: "0 auto",
                    marginBottom: "28px",
                    marginTop: "30px",
                    textAlign: "left",
                    fontFamily: "Proxima Nova Lt",
                    width: "90%",
                    fontWeight: "500",
                    color: "#3a4663",
                    fontSize: "30px"
                }}>Календарь мероприятий</h3>

            </div>
            <div style={{fontSize: "20px", color: "#212529"}}>
                Воспользуйтесь поиском, чтобы найти необходимое Вам мероприятие
            </div>
            <div>
                <input placeholder="Введите ключевые слова для поиска мероприятия"/>
            </div>
        </div>
        <div className={s.calendarBlock} style={{marginBottom: "40px"}}>
            <div>
                <Calendar defaultValue={[new Date(2023, 1, 1), new Date(2023, 1, 10)]}/>
            </div>
            <div style={{border: "1px solid gray", borderRadius: "5px", paddingTop: "20px", marginRight: "40px"}}>
                Январь 27
                <p>
                    Нет мероприятий
                </p>
            </div>
            <div style={{borderRadius: "5px", paddingTop: "20px"}}>
                Выбор категории
                <p style={{
                    borderRadius: "5px",
                    paddingTop: "10px",
                    textAlign: "start",
                    fontSize: "16px",
                    fontFamily: "Proxima Nova Rg"
                }}>
                    <div>
                        <input type="checkbox"/>Вебинар
                    </div>
                    <div>
                        <input type="checkbox"/>Выездной мониторинг
                    </div>
                    <div>
                        <input type="checkbox"/>Конкурс
                    </div>
                    <div>
                        <input type="checkbox"/>Конференция
                    </div>
                    <div>
                        <input type="checkbox"/>Круглый стол
                    </div>
                    <div>
                        <input type="checkbox"/>Окружное совещание
                    </div>
                    <div>
                        <input type="checkbox"/>Семинар
                    </div>
                    <div>
                        <input type="checkbox"/>Симпозиум
                    </div>

                </p>
            </div>
        </div>
    </>
}

export default CalendarPage