import React from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import {DownOutlined} from "@ant-design/icons"
import {useState, useEffect} from "react";
import axios from "axios";

const Reports = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/aboutus").then(
            res => {
                setData(res.data)

            })

    }, [])
    return <>
        <div className={s.newHeader}>
            <h2>
                Отчеты
            </h2>
            <Falldown header={"2021"}>
                <li>
                    <a href={`${respData[0]?.file}`}>
                        {respData[0]?.description}
                    </a>
                </li>
                <li>
                    <a href={`${respData[1]?.file}`}>
                        {respData[1]?.description}
                    </a>
                </li>
            </Falldown>
        </div>
        <div>
            <Falldown header={"2020"}>
                <li>
                    В стадии заполнения
                </li>
            </Falldown>
        </div>
        <div>
            <Falldown header={"2019"}>
                <li>
                    <a href={respData[2]?.file}>
                        {respData[2]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[3]?.file}>
                        {respData[3]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[4]?.file}>
                        {respData[4]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[5]?.file}>
                        {respData[5]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[6]?.file}>
                        {respData[6]?.description}
                    </a>
                </li>

                <li>
                    <a href={respData[7]?.file}>
                        {respData[7]?.description}
                    </a>
                </li>

                <li>
                    <a href={respData[8]?.file}>
                        {respData[8]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[9]?.file}>
                        {respData[9]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[10]?.file}>
                        {respData[10]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[11]?.file}>
                        {respData[11]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[12]?.file}>
                        {respData[12]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[13]?.file}>
                        {respData[13]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[14]?.file}>
                        {respData[14]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[15]?.file}>
                        {respData[15]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[16]?.file}>
                        {respData[16]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[17]?.file}>
                        {respData[17]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[17]?.file}>
                        {respData[17]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[18]?.file}>
                        {respData[18]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[19]?.file}>
                        {respData[19]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[20]?.file}>
                        {respData[20]?.description}
                    </a>
                </li>
                <li>
                    <a href={respData[21]?.file}>
                        {respData[21]?.description}
                    </a>
                </li>
                <li>
                <a href={respData[22]?.file}>
                    {respData[22]?.description}
                </a>
            </li>
            </Falldown>
            <div>
                <Falldown header={respData[23]?.description}>
                    <h2>
                        <a href={respData[23]?.file} style={{textDecoration: "none", color: "#3A3664"}}>
                            {respData[23]?.description}
                        </a>
                    </h2>
                </Falldown>
            </div>
        </div>
        <p></p>
    </>
}

const Falldown = ({url, header, children, style = {}}) => {

    const [active, setActive] = useState(false)
    return <div className={s.fall} style={style}>
        <div className={s.block} onClick={() => {
            active ? setActive(false) : setActive(true)
        }} onBlur={() => {
            setActive(false)
        }}>
            <div style={{width: "700px", marginTop: "40px"}}>
                <div className={s.blockHead}>
                    {header}
                </div>
                <span className={active ? s.downarrow : s.arrow}> <DownOutlined
                    style={{fontSize: '28px', color: '#fff'}}/> </span></div>

            <div className={s.ico} style={{backgroundImage: `none`, zIndex: 3, opacity: 1}}>

            </div>
        </div>
        <nav className={active ? s.activeRep : s.info}>
            <ul style={{}}>
                {children}
            </ul>
        </nav>
    </div>

}

export default Reports