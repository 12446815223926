export const suvuArray = [
    [
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 1,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Астраханское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Астраханское СУВУ»)",
            "col4": "Астраханская область",
            "col5": "414044, Астраханская обл., г. Астрахань, п. Новолесное, ул. Советской Гвардии, д. 1",
            "col6": "Смолко Павел Николаевич",
            "col9": "spu30@yandex.ru ",
            "col8": "8(8512)57-93-00",
            "col7": "http://www.spu30.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 2,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Известковское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Известковское СУВУ»)",
            "col4": "Еврейская автономная область",
            "col5": "679125, Еврейская автономная область, Облучевский район, п. Известковый, ул. Трудовая, д. 1",
            "col6": "Потехин Дмитрий Викторович",
            "col9": "izvpul@list.ru ",
            "col8": "8(42666)3-65-31",
            "col7": "http://www.spu1.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 3,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Неманское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Неманское СУВУ»)",
            "col4": "Калининградская область",
            "col5": "238710, Калининградская обл., г. Неман, ул. Советская, д. 51а",
            "col6": "Катаева Анна Васильевна",
            "col9": "gouspu_neman@mail.ru ",
            "col8": "8 (40162)-2-22-49",
            "col7": "http://www.Н-СУВУ.РФ"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 4,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Калтанское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Калтанское СУВУ»)",
            "col4": "Кемеровская область-Кузбасс",
            "col5": "652740, Кемеровская обл. - Кузбасс, г. Калтан, ул. Руставели, д. 24",
            "col6": "Гилев Алексей Николаевич",
            "col9": "spu1@mail.ru ",
            "col8": "8 (99137)2-53-43",
            "col7": "http://www.spu1.org.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 5,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Орловское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Орловское СУВУ»)",
            "col4": "Кировская область",
            "col5": "612270, Кировская обл., г. Орлов, ул. Большевиков, д. 4",
            "col6": "Хохлова Татьяна Вениаминовна",
            "col9": "Suvu_zt@spetzorlov.ru ",
            "col8": "8 (83365) 2-10-33",
            "col7": "http://www.spetzorlov.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 6,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Куртамышское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Куртамышское СУВУ»)",
            "col4": "Курганская область",
            "col5": "641430, Курганская обл., г. Куртамыш, ул. Строителей, д. 19",
            "col6": "Сердюкова Ольга Анатольевна",
            "col9": "kurtspu@mail.ru ",
            "col8": "8 (35249) 2-16-27",
            "col7": "http://www.kurtspu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 7,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Мончегорское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Мончегорское СУВУ»)",
            "col4": "Мурманская область",
            "col5": "184500, Мурманская обл., г. Мончегорск, ул. Кольская, д. 3/1",
            "col6": "Цыганков Александр Анатольевич",
            "col9": "Spu.monch@spuzt1.ru ",
            "col8": "8 (81536) 3-23-05",
            "col7": "http://www.monchsuvu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 8,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Каргатское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Каргатское СУВУ»)",
            "col4": "Новосибирская область",
            "col5": "632402, Новосибирская обл., г. Каргат, ул. Матросова, д. 59",
            "col6": "Антонюк Валерий Петрович",
            "col9": "priemnaya.kargat.spu@mail.ru ",
            "col8": "8 (38365) 2-11-57",
            "col7": "http://www.krgsptu.edusite.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 9,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Омское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Омское СУВУ»)",
            "col4": "Омская область",
            "col5": "644047, Омская обл., г. Омск, ул. 7-я Ремесленная, д. 77Б",
            "col6": "Асеев Владимир Николаевич",
            "col9": "specpu_buh@mail.ru ",
            "col8": "8(3812)78-48-92",
            "col7": "http://www.omspu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 10,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Себежское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Себежское СУВУ»)",
            "col4": "Псковская область",
            "col5": "182250, Псковская обл., г. Себеж, ул. Челюскинцев, д. 44",
            "col6": "Рябов Алексей Юрьевич",
            "col9": "lebed8@inbox.ru ",
            "col8": "8(81140) 2-13-94",
            "col7": "http://www.sebezhspu1.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 11,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Майкопское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Майкопское СУВУ»)",
            "col4": "Республика Адыгея",
            "col5": "385332, Республика Адыгея, Красногвардейский р-н, с. Новосевастопольское, ул. Чехова, д. 15",
            "col6": "Хут Аслан Туркубиевич",
            "col9": "spumaykop@yandex.ru ",
            "col8": "8(87778)5-59-32",
            "col7": "http://www.maikop-spu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 12,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Ишимбайское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Ишимбайское СУВУ»)",
            "col4": "Республика Башкортостан",
            "col5": "453214, Республика Башкортостан, г. Ишимбай, ул. Северная, д. 29",
            "col6": "Шагиева Гузель Гайсиевна",
            "col9": "sppy1@yandex.ru ",
            "col8": "8 (34794)7-85-26",
            "col7": "http://www.spetspu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 13,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Раифское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Раифское СУВУ»)",
            "col4": "Республика Татарстан",
            "col5": "422537, Республика Татарстан, Зеленодольский р-н, п. Местечко Раифа, ул. Центральная",
            "col6": "Кисиль Надежда Петровна",
            "col9": "spezraifa@mail.ru ",
            "col8": "8(84371) 3-47-39",
            "col7": "http://www.rspu-rt.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 14,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Абаканское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Абаканское СУВУ»)",
            "col4": "Республика Хакасия",
            "col5": "655004, Республика Хакасия, г. Абакан, кв-л Молодежный, д. 3",
            "col6": "Суханов Владимир Ильич",
            "col9": "abakan_spec_pu@mail.ru ",
            "col8": "8 (3902) 21-51-57",
            "col7": "http://www.spec-pu19.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 15,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Санкт-Петербургское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Санкт-Петербургское СУВУ»)",
            "col4": "г. Санкт-Петербург",
            "col5": "196652, Санкт-Петербург, г. Колпино, ул. Загородная, д. 63",
            "col6": "Миронов Валерий Владимирович",
            "col9": "spb-suvu@yandex.ru ",
            "col8": "8 (812) 461-43-21",
            "col7": "http://www.spb-specpu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 16,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Рефтинское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Рефтинское СУВУ»)",
            "col4": "Свердловская область",
            "col5": "624285, Свердловская обл., г. Асбест, п. Рефтинский, ул. Молодежная, д. 10",
            "col6": "Фадеев Александр Владимирович",
            "col9": "reftspecpu@mail.ru ",
            "col8": "8(34365)3-02-02",
            "col7": "http://www.reftsuvu.ru"
        },
        {
            "col1": "СУВУ, подведомственные Министерству просвещения\nРоссийской Федерации",
            "col2": 17,
            "col3": "Федеральное государственное бюджетное профессиональное образовательное учреждение «Щекинское специальное учебно-воспитательное учреждение закрытого типа» (ФГБПОУ «Щекинское СУВУ»)",
            "col4": "Тульская область",
            "col5": "301212, Тульская обл., Щекинский р-н, р.п. Первомайский, ул. Пролетарская, д. 5",
            "col6": "Моисеенко Нелли Вячеславовна",
            "col9": "Spu1tula@gmail.com ",
            "col8": "8(48751) 6-37-95",
            "col7": "http://www.spu1tula.net"
        },
    ],
    [
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 1,
            "col3": "Краевое государственное бюджетное общеобразовательное специальное учебно-воспитательное учреждение «Алтайская краевая специальная общеобразовательная школа закрытого типа» (КГБОСУВУДОСДООП «Алтайская краевая специальная общеобразовательная школа закрытого типа»)",
            "col4": "Алтайский край",
            "col5": "658709, Алтайский край, Каменский р-н, г. Камень-на-Оби, ул. Терешковой д. 28",
            "col6": "Филиппов Валерий Владимирович",
            "col9": "kgsu_asch@list.ru ",
            "col8": "8(38584) 3-56-28",
            "col7": "http://www.asch.edu22.info"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 2,
            "col3": "Государственное автономное общеобразовательное учреждение Амурской области «Специальное учебно-воспитательное учреждение закрытого типа, п. Юхта» (ГАОУ АО «Юхтинская спецшкола»)",
            "col4": "Амурская область",
            "col5": "676435, Амурская обл., Свободненский р-н, п. Юхта, ул. Школьная, д. 18",
            "col6": "Ганько Евгения Ивановна",
            "col9": "uhtaspec@mail.ru ",
            "col8": "8(41643)3-83-95",
            "col7": "http://www.юхтинская-спецшкола.рф"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 3,
            "col3": "Государственное бюджетное профессиональное образовательное учреждение Архангельской области «Североонежское специальное учебно-воспитательное учреждение» (ГБПОУ АО «Североонежское специальное учебно-воспитательное учреждение»)",
            "col4": "Архангельская область",
            "col5": "164268, Архангельская обл., Плесецкий р-н, пос. Североонежск, Второй мкр, д. 28",
            "col6": "Ковкова Ольга Николаевна",
            "col9": "spu.sekretar@yandex.ru ",
            "col8": "8(81832)6-51-81",
            "col7": "http://www.severspu.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 4,
            "col3": "Государственное бюджетное общеобразовательное учреждение Архангельской области «Няндомское специальное учебно -воспитательное учреждение» (ГБОУ АО «Няндомское специальное учебно-воспитательное учреждение»)",
            "col4": "Архангельская область",
            "col5": "164232, Архангельская обл., Няндомский р-н, г. Няндома, мкр. Каргополь-2, ул. Лесная, стр. 2",
            "col6": "Кузьмичев Андрей Игоревич",
            "col9": "speck333@mail.ru ",
            "col8": "8(81838)7-83-31",
            "col7": "http://www.nyndoma.nubex.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 5,
            "col3": "Государственное казенное общеобразовательное специальное учебно-воспитательное учреждение «Октябрьская школа закрытого типа» (ГКОСУВУ «ОШЗТ»)",
            "col4": "Волгоградская область",
            "col5": "404321, Волгоградская обл., Октябрьский р-н, рп. Октябрьский, ул. Садовая, д. 1",
            "col6": "Ромашкин Александр Александрович",
            "col9": "oktshzt@volganet.ru ",
            "col8": "8(84475)6-10-76",
            "col7": "http://www.oktshzt.nubex.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 6,
            "col3": "Бюджетное общеобразовательное учреждение Вологодской области «Специальное учебно-воспитательное учреждение» (закрытого типа) (БОУ ВО «Специальное учебно-воспитательное учреждение»)",
            "col4": "Вологодская область",
            "col5": "162560, Вологодская обл., Шекснинский р-н, п. Шексна, ул. Детская, д. 21",
            "col6": "Чердынцев Александр Алексеевич",
            "col9": "speshkola.2012@yandex.ru smirnovamv83@mail.ru",
            "col8": "8(81751) 2-19-23",
            "col7": "http://www.s28010.edu35.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 7,
            "col3": "Государственное общеобразовательное учреждение «Сретенское специальное учебно-воспитательное учреждение закрытого типа» (ГОУ «Сретенское специальное учебно-воспитательное учреждение закрытого типа»)",
            "col4": "Забайкальский край",
            "col5": "673500, Забайкальский край, г. Сретенск, мкр. Восточный, д. 27",
            "col6": "И.о. директора Гусевская Марина Олеговна",
            "col9": "spec_srt@mail.ru ",
            "col8": "8(30246)2-14-59",
            "col7": "http://www.guv_srtn.srtn.zabedu.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 8,
            "col3": "Государственное учебно-воспитательное общеобразовательное учреждение Иркутской области «Специальная (коррекционная) общеобразовательная школа» (ГСУВОУ «Специальная (коррекционная) общеобразовательная школа»)",
            "col4": "Иркутская область",
            "col5": "665806, Иркутская обл., г. Ангарск, мкр. Северный, ул. Тимирязева, д. 1",
            "col6": "Быков Виктор Александрович",
            "col9": "Specshkola_irk@mail.ru Specshkolairk@ уandex.ru",
            "col8": " 8(3955)51-31-10 8(3955)51-31-21",
            "col7": "http://www.спецшк-тельма.образование38.рф"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 9,
            "col3": "Государственное специальное учебно-воспитательное общеобразовательное учреждение «Кемеровская специальная общеобразовательная школа» имени народного учителя СССР Э.Г. Фельде (ГСУВОУ КСОШ им. Э.Г. Фельде)",
            "col4": "Кемеровская область-Кузбасс",
            "col5": "650504, Кемеровская обл.-Кузбасс, Кемеровский муниципальный округ, с. Верхотомское, ул. Школьная, д. 20",
            "col6": "Шупиков Александр Владимирович",
            "col9": "special_school@mail.ru ",
            "col8": "8(3842)60-00-67",
            "col7": "http://www.specialschool.narod.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 10,
            "col3": "Государственное казенное специальное учебно-воспитательное учреждение закрытого типа общеобразовательная школа Краснодарского края (ГКСУВУЗТ ОШ КК)",
            "col4": "Краснодарский край",
            "col5": "352762, Краснодарский край, Брюховецкий р-н, ст. Переяславская, ул. Октябрьская, д. 14",
            "col6": "Лысенков Николай Анатольевич",
            "col9": "Specshkola@yandex.ru ",
            "col8": "8 (86156) 6-10-54",
            "col7": "http://www.specshkola.com"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 11,
            "col3": "Государственное бюджетное общеобразовательное учреждение Ленинградской области «Сланцевское специальное учебно-воспитательное учреждение закрытого типа» (ГБОУ ЛО «Сланцевское специальное учебно-воспитательное учреждение закрытого типа»)",
            "col4": "Ленинградская область",
            "col5": "188561, Ленинградская обл., Сланцевский р-н, д. Большие Поля, д. 98",
            "col6": "Степанова Наталья Михайловна",
            "col9": "188560spec@mail.ru ",
            "col8": "8(81374)2-20-78",
            "col7": "http://www.slanspecschool.nubex.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 12,
            "col3": "Государственное казенное общеобразовательное учреждение Московской области «Каширская специальная учебно-воспитательная школа закрытого типа» (ГКОУ МО «Каширская спецшкола»)",
            "col4": "Московская область",
            "col5": "142900, Московская обл., г.о. Кашира, дер. Лиды, Иваньковское шоссе, д. 6",
            "col6": "Емельянов Юрий Леонович",
            "col9": "specshkolakashira@yandex.ru ",
            "col8": "8(496) 693-17-27",
            "col7": "http://www.specshkolakashira. edusite.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 13,
            "col3": "Государственное казенное общеобразовательное учреждение Московской области «Чеховская специальная учебно- воспитательная школа закрытого типа» (ГКОУ МО «Чеховская спецшкола»)",
            "col4": "Московская область",
            "col5": "142304, Московская обл., г.о. Чехов, д. Скурыгино, ул. Лесная, д. 1А",
            "col6": "Устюжанин Александр Григорьевич",
            "col9": "mo_chehspecsch@mosreg.ru chekhovspets2@mail.ru",
            "col8": "8 (496) 727-62-41",
            "col7": "http://www.spschoolch.org.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 14,
            "col3": "Краевое государственное казенное общеобразовательное учреждение «Специальное учебно-воспитательное учреждение закрытого типа «Очёрская спецшкола» (КГКОУ «Очерское СУВУ»)",
            "col4": "Пермский край",
            "col5": "617140, Пермский край, г. Очёр, ул. Ленина, д. 3",
            "col6": "Турова Ольга Валентиновна",
            "col9": "spskool@yandex.ru",
            "col8": "8(342)783-19-02",
            "col7": "http://www.59434s0sp.edusite.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 15,
            "col3": "Краевое государственное общеобразовательное бюджетное учреждение «Приморское специальное учебно-воспитательное учреждение имени Т.М. Тихого» (КГОБУ «Приморская спецшкола»)",
            "col4": "Приморский край",
            "col5": "692506, Приморский край, г. Уссурийск, ул. Каховская, д.17",
            "col6": "Мозжухина Оксана Александровна",
            "col9": "specscool@mail.ru ",
            "col8": "8(4234)265862",
            "col7": "http://www.prim.ddpk.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 16,
            "col3": "Государственное казенное Серафимовское специальное учебно-воспитательное общеобразовательное учреждение закрытого типа (Серафимовская спецшкола)",
            "col4": "Республика Башкортостан",
            "col5": "452781, Республика Башкортостан, Туймазинский р-н, с. Серафимовское, ул. Дзержинского, д. 47",
            "col6": "Аднагулов Рашит Карамутдинович",
            "col9": "20270@mail.ru ",
            "col8": "8(34782)20-20-70",
            "col7": "http://www.спецшкола-02.рф"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 17,
            "col3": "Государственное специальное учебно-воспитательное общеобразовательное учреждение «Республиканская специальная общеобразовательная школа закрытого типа» (ГСУВОУ «РСОШЗТ»)",
            "col4": "Республика Бурятия",
            "col5": "671339, Республика Бурятия, Заиграевский р-н, улус Шулута, д. 60",
            "col6": "Цыбиков Валерий Владимирович",
            "col9": "rsoshzt@mail.ru ",
            "col8": "8 (31136) 58-2-33 89148410037",
            "col7": ""
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 18,
            "col3": "Государственное казенное специальное учебно-воспитательное общеобразовательное учреждение закрытого типа «Республиканская специальная общеобразовательная школа имени Н.А. Галлямова» (РСОШ)",
            "col4": "Республика Татарстан",
            "col5": "420006, Республика Татарстан, г. Казань, ул. Рахимова, д.23а",
            "col6": "Кладов Дмитрий Юрьевич",
            "col9": "rsosh-kazan@mail.ru ",
            "col8": "8(843) 557-50-04",
            "col7": "http://www.edu.tatar.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 19,
            "col3": "Государственное казенное общеобразовательное учреждение Ростовской области «Специальное учебно-воспитательное учреждение закрытого типа» («Ростовская областная спецшкола»)",
            "col4": "Ростовская область",
            "col5": "346009, Ростовская обл., Чертковский р-н, с. Маньково-Калитвенское, пер. Луначарского, д. 39",
            "col6": "Тищенко Владимир Николаевич",
            "col9": "rsosh61@yandex.ru ",
            "col8": "8(86387) 47-9-04",
            "col7": "http://www.rsosh61.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 20,
            "col3": "Государственное казенное общеобразовательное специальное учебно-воспитательное учреждение города Октябрьска Самарской области (ГКО СУВУ г. Октябрьска)",
            "col4": "Самарская область",
            "col5": "445244, Самарская обл., г. Октябрьск, ул. Сакко и Ванцетти, д. 15",
            "col6": "Дамм Владимир Владимирович",
            "col9": "gvu_okt@samara.edu.ru ",
            "col8": "8(846-46) 2-11-15",
            "col7": "http://www.spetsschool.minobr63.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 21,
            "col3": "Государственное бюджетное специальное учебно-воспитательное общеобразовательное учреждение Саратовской области «Марксовская специальная общеобразовательная школа закрытого типа» (ГБОУ СО «Марксовская специальная общеобразовательная школа закрытого типа»)",
            "col4": "Саратовская область",
            "col5": "413090, Саратовская обл., г. Маркс, ул. К. Маркса, д. 119",
            "col6": "Шаров Сергей Владимирович",
            "col9": "specschool.marx@mail.ru ",
            "col8": "8(845-67)5-40-52",
            "col7": "http://www.spec64.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 22,
            "col3": "Сахалинское государственное казенное специальное учебно-воспитательное общеобразовательное учреждение закрытого типа (СГКСУВОУ закрытого типа)",
            "col4": "Сахалинская область",
            "col5": "694650, Сахалинская обл., Холмский р-н, с. Костромское, ул. Школьная, д. 1",
            "col6": "Сек Елена Николаевна",
            "col9": "mo.gksuvusoshzt@sakhalin.gov.ru ",
            "col8": "8 (42433) 98-242",
            "col7": "http://www.sakhspecschool.edusite.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 23,
            "col3": "Государственное казенное общеобразовательное учреждение Свердловской области «Специальное учебно-воспитательное учреждение закрытого типа» (ГКОУ СО «СУВУ»)",
            "col4": "Свердловская область",
            "col5": "620024, Свердловская область, г. Екатеринбург, ул. Бисертская, д. 143",
            "col6": "Поддубная Наталия Яковлевна",
            "col9": "info@124ural.ru ",
            "col8": "8 (343) 289-19-18",
            "col7": "http://www.124ural.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 24,
            "col3": "Государственное казенное специальное учебно-воспитательное учреждение «Горнозаводская специальная общеобразовательная школа закрытого типа» (ГКСУВУ «Горнозаводская специальная общеобразовательная школа закрытого типа»)",
            "col4": "Ставропольский край",
            "col5": "357312, Ставропольский край, Кировский р-н, с. Горнозаводское, пер. Кооперативный, д.2",
            "col6": "Любенко Степан Михайлович",
            "col9": "specshcoool@mail.ru ",
            "col8": "8 (87938)5 53 22",
            "col7": "http://www.specshcool.narod.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 25,
            "col3": "Казенное общеобразовательное учреждение Ханты-Мансийского автономного округа – Югра «Специальная учебно-воспитательная школа № 2» (КОУ «Специальная учебно-воспитательная школа № 2»)",
            "col4": "Ханты-Мансийский автономный округ - Югра",
            "col5": "628422, Ханты­Мансийский автономный округ – Югра, г. Сургут, пр-д Макаренко, д. 2",
            "col6": "Сосновская Ирина Викторовна",
            "col9": "sosh-zt1@mail.ru ",
            "col8": "8(3462) 95-80-32",
            "col7": "http://www.suvsh-2.ru"
        },
        {
            "col1": "СУВУ закрытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 26,
            "col3": "Государственное казенное учебно-воспитательное общеобразовательное учреждение для обучающихся с девиантным поведением «Челябинская областная специальная общеобразовательная школа закрытого типа» (Челябинская областная специальная общеобразовательная школа)",
            "col4": "Челябинская область",
            "col5": "454017, Челябинская обл., г. Челябинск, ул. Лазурная, д. 12",
            "col6": "Малхасян Инна Маратовна",
            "col9": "szt174@yandex.ru ",
            "col8": "8(351)736-07-97 8(351)736-08-46",
            "col7": "http://www.szt74.ru"
        },
    ],
    [
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 1,
            "col3": "Государственное специальное учебно-воспитательное общеобразовательное учреждение «Губернаторская специальная общеобразовательная школа» (ГСУВУ «Губернаторская специальная общеобразовательная школа»)",
            "col4": "Кемеровская область-Кузбасс",
            "col5": "652320, Кемеровская обл. -Кузбасс, Топкинский муниципальный округ, с. Топки, ул. Топкинская роща, 2",
            "col6": "Гончаров Сергей Александрович",
            "col9": "roscha2015@mail.ru ",
            "col8": "8(3842) 66-00-92",
            "col7": "http://www.губернаторская-школа.рф"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 2,
            "col3": "Государственное бюджетное профессиональное образовательное учреждение Республики Хакасия «Боградское СУВУ открытого типа» (ГБПОУ РХ «Боградское СУВУ»)",
            "col4": "Республика Хакасия",
            "col5": "655340, Республика Хакасия, Боградский р-н, с. Боград, ул. Советская, д. 140А",
            "col6": "Медведев Александр Сергеевич",
            "col9": "gou-pu13@yandex.ru ",
            "col8": "+7(39034) 9-52-30 +7 (39034) 9-17-83 +7 (39034) 9-52-48",
            "col7": "http://www.bograd-pu13.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 3,
            "col3": "Государственное бюджетное общеобразовательное учреждение города Москвы «Специальное учебно-воспитательное учреждение № 1» (ГБОУ Спецшкола № 1)",
            "col4": "г. Москва",
            "col5": "125239, г. Москва, ул. Михалковская, д. 15А",
            "col6": "Дубяга Светлана Михайловна",
            "col9": "spec1@edu.mos.ru ",
            "col8": "8(499) 153-91-21",
            "col7": "http://www.schs1.mskobr.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 4,
            "col3": "Государственное казенное общеобразовательное учебно-воспитательное учреждение «Специальная школа № 27 открытого типа» (ГКОУВУ «Специальная школа № 27 открытого типа»)",
            "col4": "Нижегородская область",
            "col5": "603053, Нижегородская обл., г. Нижний Новгород, пр-т Бусыгина, д. 39",
            "col6": "Гурьева Светлана Олеговна",
            "col9": "schooln27@inbox.ru ",
            "col8": "8 (831) 253-57-71",
            "col7": "http://www.27.ucoz.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 5,
            "col3": "Государственное казенное специальное учебно-воспитательное общеобразовательное учреждение «Специальная общеобразовательная школа открытого типа (для мальчиков) Оренбургской области (ГКОУ «Специальная школа»)",
            "col4": "Оренбургская область",
            "col5": "461503, Оренбургская обл., Соль-Илецкий р-н, г. Соль-Илецк, ул. им. Н.Ф. Жидовинова, д.1",
            "col6": "Маркелова Лариса Викторовна",
            "col9": "iu41@mail.orb.ru ",
            "col8": "8 (35336) 2-96-66",
            "col7": "http://www.sp-school.ucoz.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 6,
            "col3": "Муниципальное бюджетное общеобразовательное учреждение «Специальное учебно-воспитательное учреждение открытого типа – основная общеобразовательная школа № 14 «Подросток» (МБОУ «СУВУ № 14 «Подросток»)",
            "col4": "Пермский край",
            "col5": "618200, Пермский край, г. Чусовой, ул. Ленина, д. 12",
            "col6": "Максимова Василиса Михайловна",
            "col9": "podrostok.14maksimova@yandex.ru ",
            "col8": "8(342)565-17-69",
            "col7": "http://www.14chus.uralschool.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 7,
            "col3": "Муниципальное бюджетное общеобразовательное учреждение «Специальное учебно-воспитательное учреждение — основная общеобразовательная школа открытого типа» г. Чайковского (МБОУ СУВУ ООШОТ г. Чайковского)",
            "col4": "Пермский край",
            "col5": "617762, Пермский край, г. Чайковский, ул. Кочетова, д. 6",
            "col6": "Котова Людмила Павловна",
            "col9": "soshot2004@mail.ru ",
            "col8": "8(342)416-05-90",
            "col7": "http://www.soshot.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 8,
            "col3": "Краевое государственное бюджетное общеобразовательное учреждение «Специальное учебно-воспитательное учреждения для обучающихся с девиантным (общественно-опасным) поведением «Уральское подворье» (КГБОУСУВУ «Уральское подворье»)",
            "col4": "Пермский край",
            "col5": "614065, Пермский край, г. Пермь, ул. Космонавта Беляева, д. 43/3",
            "col6": "Круглова Ольга Витальевна",
            "col9": "podvorie-dir@mail.ru ",
            "col8": "8(342) 284-88-20",
            "col7": "http://www.uralskoepodvorie.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 9,
            "col3": "Государственное бюджетное общеобразовательное учреждение Псковской области «Специальное учебно-воспитательное учреждение» (ГБОУ ПО «Специальное учебно-воспитательное учреждение»)",
            "col4": "Псковская область",
            "col5": "182500, Псковская обл., г. Невель, ул. Гвардейская, д. 120",
            "col6": "Ширкин Александр Александрович",
            "col9": "org356@pskovedu.ru ",
            "col8": "8(81151)2-18-46",
            "col7": "http://www.org356.pskovedu.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 10,
            "col3": "Государственное бюджетное специальное учебно-воспитательное профессиональное образовательное учреждение «Специальное профессиональное училище открытого типа» (ГБСУВПОУ Спец ПУ)",
            "col4": "Республика Северная Осетия-Алания",
            "col5": "363110, Республика Северная Осетия - Алания, Пригородный р-н, с. Михайловское, ул. Студенческая, д. 1",
            "col6": "Кудаков Виктор Лазарьевич",
            "col9": "specpu@edu15.ru ",
            "col8": "8 (8672) 23-00-27",
            "col7": "http://www.specpu.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 11,
            "col3": "Государственное казенное общеобразовательное специальное учебно- воспитательное учреждение школа № 2 (открытого типа) Санкт-Петербурга (Специальная общеобразовательная школа №2 (открытого типа))",
            "col4": "г. Санкт-Петербург",
            "col5": "197341, г. Санкт-Петербург, ул. Аккуратова, д. 11А",
            "col6": "Майорова Татьяна Олеговна",
            "col9": "sosh2@mail.ru ",
            "col8": "8(812) 241-25-13",
            "col7": "http://www.sch002.petersburgedu.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 12,
            "col3": "Областное государственное казенное специальное учебно-воспитательное общеобразовательное учреждение открытого типа «Александровская школа-интернат» (ОГКОУ «Александровская школа – интернат»)",
            "col4": "Томская область",
            "col5": "634582, Томская область, Томский район, с. Александровское, ул. Коммунистическая, д. 65",
            "col6": "И.о. директора Пименова Наталья Анатольевна",
            "col9": "aoshkola@mail.ru ",
            "col8": "8(3822) 96-00-34 8(3822) 960-111",
            "col7": "http://www.tomaldiv.edu.tomsk.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 13,
            "col3": "Муниципальное бюджетное специальное учебно-воспитательное общеобразовательное учреждение «Специальная общеобразовательная школа открытого типа № 202» (МБСУВОУ «Школа № 202»)",
            "col4": "Челябинская область",
            "col5": "456783, Челябинская обл., г. Озерск, ул. Герцена, д. 7",
            "col6": "Киршина Светлана Васильевна",
            "col9": "mbsu202@bk.ru ",
            "col8": "8(351-30) 4-48-38",
            "col7": "http://www.mbsu202.ru"
        },
        {
            "col1": "СУВУ открытого типа, подведомственные органам исполнительной власти\nсубъектов Российской Федерации, осуществляющих государственное\nуправление в сфере образования",
            "col2": 14,
            "col3": "Казенное специальное учебно-воспитательное общеобразовательное учреждение Воронежской области «Аннинская специальная общеобразовательная школа» (КОУ ВО «Аннинская специальная общеобразовательная школа»)",
            "col4": "Воронежская область",
            "col5": "396251, Воронежская обл., Аннинский район, пгт. Анна, ул. Ватутина, д. 44",
            "col6": "Порокина Марина Александровна",
            "col9": "annaspecschool@mail.ru",
            "col8": "8(47346)2-76-41",
            "col7": "http://www.speczshkola.nubex.ru"
        }
    ]
]