import React from "react";
import s from './openShop.module.css'


const OpenShop = () => {
    return <>
        <div className={s.newHeader}>
            <h2>
                Открытые закупки
            </h2>
        </div>
        <div className={s.open} style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#212529",
            textAlign: "left",
            width: "64vw",
            margin: "30px auto",
            maxWidth: "1200px",
            fontFamily: "Proxima Nova Lt",
            fontSize: "19px"
        }}>
            <div className={s.open}>
                Федеральное государственное бюджетное учреждение «Центр защиты прав и интересов детей» осуществляет
                закупки в соответствии с Федеральным законом от 05 апреля 2013 г. № 44-ФЗ «О контрактной системе в сфере
                закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд».
                <p></p>
                План-график закупок Федерального государственного бюджетного учреждения «Центр защиты прав и интересов
                детей» и вносимые в него изменения размещаются на официальном сайте единой информационной системы в
                сфере закупок <a
                href="https://zakupki.gov.ru/epz/orderplan/pg2020/general-info.html?plan-number=202201954000004001">www.zakupki.gov.ru</a> в
                порядке, установленном Федеральным законом от 05 апреля 2013 г. №
                44-ФЗ «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и
                муниципальных нужд».
                <p></p>
                Информация о закупках, проводимых Федеральным государственным бюджетным учреждением «Центр защиты прав и
                интересов детей» конкурентными способами, размещается на официальном сайте единой информационной системы
                в сфере закупок www.zakupki.gov.ru и на сайте электронной торговой площадки <a
                href="https://www.sberbank-ast.ru/">Сбербанк-АСТ</a>.
            </div>
            <p>

            </p>
            Отдел сопровождения конкурсных процедур:
            <p></p>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "50px"}}>
                <div>
                    Начальник отдела-контрактный управляющий
                </div>
                <div>

                    Смеющева Оксана Николаевна
                </div>
                <div className={s.email}>

                    <a href="zakupki.centr@fcprcgov.ru">zakupki.centr@fcprcgov.ru</a>
                </div>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "50px"}}>
                <div>

                    Юрисконсульт 
                </div>
                <div>

                    Бородкин Дмитрий Валерьевич
                </div>
                <div className={s.email}>

                    <a href="mailto:d.borodkin@fcprcgov.ru">d.borodkin@fcprcgov.ru</a>
                </div>
            </div>
        </div>
    </>
}

export default OpenShop