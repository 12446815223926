import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img1 from "../../assets/News/News260824_1.jpeg"
import img2 from "../../assets/News/News260824_2.png"
import img3 from "../../assets/News/News260824_3.png"

const News260824 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинары для сотрудников СУВУ «Альтернативные методы предупреждения и разрешения конфликтов в СУВУ» и
            «Организация и развитие службы медиации/примирения в СУВУ»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Продолжается деятельность Центра защиты прав и интересов детей по сопровождению служб медиации в специальных
            учебно-воспитательных учреждениях.
            <p></p>
            20 августа 2024 г. прошёл ознакомительный вебинар «Альтернативные методы предупреждения и разрешения
            конфликтов в СУВУ» для сотрудников специальных учебно-воспитательных учреждений, в которых созданы и
            функционируют службы медиации и примирения.
            <p></p>
            Вебинар проводился с целью приобретения и актуализации знаний сотрудников служб медиации/примирения в СУВУ
            об альтернативных способах урегулирования и профилактики конфликтов и споров, а также на тех сотрудников,
            которые планируют войти в состав служб медиации/примирения.
            <p></p>
            Ведущий вебинара вместе с участниками рассмотрели базовые понятия, принципы и технологии медиативного
            подхода, разобрали практические кейсы.
            <p></p>
            22 августа 2024 г. состоялся ознакомительный вебинар для руководителей и сотрудников СУВУ, в которых идет
            становление служб медиации/примирения «Организация и развитие службы медиации/примирения в СУВУ».
            <p></p>
            На вебинаре разбирались особенности организации и развития службы медиации/примирения в СУВУ и вопрос
            использования медиативного подхода для эффективного взаимодействия сотрудников различных служб и
            подразделений СУВУ.
            <p></p>
            В ходе встреч специалисты Центра также познакомили сотрудников СУВУ с понятием конфликта и способами его
            разрешения.
            <p></p>
            Ведущими вебинаров выступили аналитик I категории Центра защиты прав и интересов детей Тюльканов Сергей
            Леонидович и аналитик отдела медиации Быкова Марина Викторовна.
        </div>
        <div>
            <img src={img2}/>
        </div>
        <div>
            <img src={img3}/>
        </div>
        <ToNews/>
    </div>
}


export default News260824;