import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Behavior/p3.jpg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {DownOutlined} from "@ant-design/icons"


const hrefsArr = [
    {text: "Нормативно-правовые материалы", to: "/spec-value-of-life/normativno-pravovye-materialy"},
    {
        text: "Информационно-методические материалы для специалистов",
        to: "/spec-value-of-life/metodicheskie-materialy-dlya-spetsialistov"
    },
    {
        text: "Информационные материалы для родителей и детей",
        to: "/spec-value-of-life/informatsionnye-materialy-dlya-roditelej"
    },
    {text: "Видеоматериалы", to: "/spec-value-of-life/video-price"},
    {text: "Полезные ссылки", to: "/spec-value-of-life/poleznye-ssylki"},
]
const PagePriceLife = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.page}>
        <div className={s.content}>
            <div className={s.newHeader}>
                <h2>
                    Ценность жизни — обеспечение вопросов детской безопасности
                </h2>
            </div>
            <div>
                <img src={logo}/>
            </div>

            <div style={{fontSize: "19px"}}>
                Отдел сопровождения вопросов безопасности детства является
                структурной единицей ФГБУ «Центр защиты прав и интересов детей»
                и курирует вопросы обеспечения психологической безопасности
                образовательной среды, профилактики детской смертности, суицидального,
                рискованного и аутодеструктивного поведения у обучающихся.
                В целях методической поддержки педагогов-психологов, педагогов
                и других специалистов, ответственных за профилактическую работу в
                системе образования, а также в целях поддержки родителей (законных
                представителей) обучающихся и самих обучающихся отдел сопровождения
                вопросов безопасности детства:
                <ul>
                    <li style={{marginBottom: "10px"}}>
                        проводит ежегодные мониторинги профилактической работы
                        по предупреждению суицидов среди обучающихся,
                    </li>
                    <li style={{marginBottom: "10px"}}>
                        <div></div>
                        осуществляет разработку методических материалов в рамках
                        вопросов, курируемых отделом,
                    </li>

                    <li style={{marginBottom: "10px"}}>
                        <div></div>
                        проводит вебинары по актуальным вопросам безопасности
                        детства,
                    </li>

                    <li style={{marginBottom: "10px"}}>
                        <div></div>
                        осуществляет экспертизу учебно-методических материалов,
                        разработанных иными организациями и посвященных вопросам
                        безопасности детства,
                    </li>

                    <li style={{marginBottom: "10px"}}>
                        <div></div>
                        разрабатывает просветительские материалы для родителей
                        (законных представителей) обучающихся и для самих обучающихся.
                    </li>
                </ul>
            </div>

        </div>
        <div style={{marginTop: "57px"}}>
            <Hrefs/>
            {/*<WhiteButton text="Ресурсы профилактики суицида" to="/cuic"/>
            <WhiteButton text="Информационная безопасность" to="/infSec"/>
        <WhiteButton text="Твой кибермаршрут" to="/secWay"/>*/}
        </div>


    </div>


}

export const Hrefs = ({hrefs}) => {
    return <div className={s.hrefs}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, href, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <NavLink to={to}>
                    {text}
                </NavLink>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}
const PinkButton = ({text, stylesheet}) => {
    return <div className={s.pinkbutton}>
        {text}

        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}
const WhiteButton = ({text, stylesheet, to}) => {
    return <div className={s.whitebutton}>
        <NavLink to={to}>

            {text}

            <ArrowRightOutlined/>


        </NavLink>
    </div>
}

export default PagePriceLife