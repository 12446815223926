import React from "react";
import { HeaderBlock } from "./Header";
import s from "./Header.module.css"
import ico1 from "../../assets/SVG/01.svg"
import ico2 from "../../assets/SVG/02.svg"
import ico22 from "../../assets/SVG/03.svg"
import ico4 from "../../assets/SVG/08.svg"
import ico3 from "../../assets/SVG/04.svg"
import ico5 from "../../assets/SVG/05.svg"
import ico6 from "../../assets/SVG/06.svg"
import gor1 from "../../assets/img/gor-lin-a1.png"
import gor2 from "../../assets/img/gor-lin-a2.png"
import gor3 from "../../assets/img/mediac.gif"
import ImgPopup from "../common/Popup/PopupImg";
import { NavLink } from "react-router-dom";


const HeaderMobile = ({active, setActive}) => {
    return<>
    <ImgPopup active={active} setActive={setActive}>
    <div className={s.mobile} >
      <div className={s.leftmob}>
      <div className={s.hotlineM}>
            
            <div className={s.blockM}>
                <img  src={gor1}/> 
                <a href="/hotline1">
               <span>
               <b>Горячая линия </b>
                    Тестирование обучающихся
               </span>    
               </a>               
            </div>
            <div className={s.blockM}>
            <img  src={gor2}/> 
            <a href="https://frcopeca.ru/goryachaya-liniya-po-voprosam-realizatsii-zhilishhnyh-prav-detej-sirot/">
            <span>
            <b>Горячая линия </b>
                    Жилищные права
            </span>
            </a>
            </div>
            <div className={s.blockM}>
            <img  src={gor3}/> 
            <a href="/hotline3">
            <span>
                <b>Горячая линия </b>
                    Примирение и медиация
            </span>
            </a>
            </div>
        </div>
        <div>
        <ul>
            <li>
              <NavLink to="/aboutUs">Информация</NavLink>
            </li>
            <li>
              <a href="/news">Новости</a>
            </li>
            <li>
              <a href="/structure">Структура центра</a>
            </li>
            <li>
              <NavLink to="/maindir">Ключевые направления</NavLink>
            </li>
            <li>
              <NavLink to="/reports">Отчеты</NavLink>
            </li>
            <li>
              <NavLink to="/files-ustav">Уставные документы</NavLink>
            </li>
            <li>
              <NavLink to="/gosexercise">Государственное задание</NavLink>
            </li>
            <li>
              <NavLink to="/openshops">Открытые закупки</NavLink>
            </li>
            <li>
              <NavLink to="/pfhd">ПФХД</NavLink>
            </li>
            <li>
              <NavLink to="/files-income">Сведения о доходах</NavLink>
            </li>
            <li>
              <NavLink to="/files-anticor">Противодействие коррупции</NavLink>
            </li>
            <li>
              <a href="http://80.87.197.48:8666/media/media/behavior/4.2_Doklad-ob-antimonopolnom-komplaense-za-2021-god-ot-14.03.2022-1.pdf">Антимонопольное законодательство</a>
            </li>
            <li>
              <a href="http://80.87.197.48:8666/media/media/behavior/20-1-29-04-2021.pdf">Политика обработки персональных данных</a>
            </li>
            <li>
              <NavLink to="/contacts">Контакты</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.rightmob}>
        <div>

        Мониторинги
        </div>
      <div>

        Образование
      </div>
        <div>
        Вебинары
        </div>
        <div>


        Методические разработки
        </div>
        <div>
        Обращение граждан
        </div>
      </div>
      
    </div>
          </ImgPopup>
        </>
}

export default HeaderMobile