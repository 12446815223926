import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/4.jpg"
import logo from "../../../assets/Directions/6_1.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import {DownOutlined} from "@ant-design/icons"

import {NavLink} from "react-router-dom";
import img1 from "../../../assets/SUVU/1-1.png"
import img2 from "../../../assets/SUVU/2-1.png"
import img3 from "../../../assets/SUVU/3-1.png"
import SimpleImageSlider from "react-simple-image-slider";


const hrefsArr = [
    {text: "Новости", to: "/spec-suvu/news-suvu/"},
    {text: "Нормативное регулирование деятельности СУВУ", to: "/spec-suvu/normativno-pravovye-dokumenty/"},
    {
        text: "Информационно-методические материалы",
        to: "/spec-suvu/informatsionno-analiticheskie-materialy/"
    },
    {text: "Всероссийские мероприятия", to: "/spec-suvu/meropriyatiya/"},
    {text: "Люди. События. Факты.", to: "/spec-suvu/ludi-fakti-sobitiya/"},
]
const PageFRCSUVU = ({url, header, children, style = {}}) => {
    return <div style={{width: "1200px", margin: "0 auto"}} className={s.SUVU}>
        <h2 style={{fontWeight: "normal"}}>
            <div>
                <img src={logo} style={{height: "115px", width: "110px"}}/>
            </div>
            <span style={{
                fontSize: "30px",
                fontFamily: "Proxima Nova Lt",
                color: "#3A4663"
            }}>Федеральный ресурсный центр специальных учебно-воспитательных<br/>учреждений (ФРЦ СУВУ)</span>
        </h2>
        <div className={s.page}>
            <div className={s.content}>
                <p style={{
                    fontSize: "18px",
                    color: "#5e5a5a",
                    fontFamily: 'Proxima Nova Lt',
                    fontWeight: 300,
                    lineHeight: 1.5
                }}>
                    <div class="row">
                        <SimpleImageSlider images={[img1, img2, img3]} width={700}
                                           height={474} showBullets={true}
                                           showNavs={true}/>
                    </div>
                </p>
                <p></p>
            </div>
            <div>
                <BlackButton text="Навигатор СУВУ" type="a" refs={"/navigator/"}/>
                <BlackButton text="Личные кабинеты" type="a" refs="https://lk-suvu.fcprc.ru/"/>
                <p></p>
                <Hrefs/>


            </div>
        </div>
    </div>


}

const Hrefs = ({hrefs, to}) => {
    return <div className={s.hrefs} >
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} href={el.href} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <NavLink to={to}>
            <li style={{position: "relative", marginRight: "20px"}}>
                <div style={{marginRight: "20px"}}>
                    <img src={docIco}/>

                    {text}

                    {input ? <span
                            style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                            onClick={() => {
                                isActive ? SetActive(false) : SetActive(true)
                            }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                        : <></>}
                </div>
            </li>
        </NavLink>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>

}

const BlackButton = ({text, stylesheet, type, refs}) => {
    return <div className={s.blackbutton}>
        {type === "a" ? <a href={refs}>{text}</a> : <NavLink to={refs}>{text}</NavLink>}
        <div>
            <ArrowRightOutlined/>
        </div>
    </div>
}

export default PageFRCSUVU