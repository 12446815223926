import React from "react";
import ico from "../../../assets/fileicored.svg"
import arr from "../../../assets/arrow.svg"
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {Hrefs} from "../PageInfo/PageInfoFRCKDN"
import {hrefsArrKDN} from "./FilesKDNGos";
import {FileLeftLineKDN, HrefsKDN} from "./FilesKDNNews";

const hrefsArr = [
    {text: "Новости и события", to: "/kdnnews"},
    {text: "Мероприятия", input: [], to: "/kdnivents"},
    {text: "О КДНиЗП", input: [], to: "/kdnHistory"},
    {text: "Правительственная КДНиЗП", to: "/kdnGos"},
    {text: "Методологические материалы", to: "/kdnDev"},
    {text: "Нормативная правовая база", to: "/kdnRights"},
    {text: "Всероссийские совещания КДНиЗП", input: ["2021 год", "Контакты"], to: "/kdnInternational"},
    {text: "Контакты", to: "/kdnContacts"},
    {text: "Актуальные интернет-ресурсы по вопросам профилактики", to: "/kdnActiveQ"},
    {text: "Реестр НКО", to: "/kdnReestr"},
    {text: "Сведения по форме ФСН №1-КДН", to: "/kdnInfoForm"},

]
const FilesKDNInt = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/kdn").then(
            res => {
                setData(res.data)
            })

    }, [])
    return <>

        <div className={s.filesLeftPage}>
            <div className={s.newHeader}>
                <h2>
                    Всероссийские совещания КДНиЗП
                </h2>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2023 год
                </h1>
                <FileLeftLineKDN text={respData[91]?.description} refers={respData[91]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[90]?.description} refers={respData[90]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[89]?.description} refers={respData[89]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[88]?.description} refers={respData[88]?.file} ico={ico}/>
                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2022 год
                </h1>
                <FileLeftLineKDN text={respData[50]?.description} refers={respData[50]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[51]?.description} refers={respData[51]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[52]?.description} refers={respData[52]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[53]?.description} refers={respData[53]?.file} ico={ico}/>
                <FileLeftLineKDN text={respData[54]?.description} refers={respData[54]?.file} ico={ico}/>

                <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                    2021 год
                </h1>
                <FileLeftLineKDN
                    text={"VIII Всероссийское совещание по вопросу организации деятельности комиссий по делам несовершеннолетних и защите их прав «Маршрут межведомственного взаимодействия органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних: современный контекст и новые модели»"}
                    refers={"/eight-vspv"} ico={arr}/>
                <div>
                    <h1 style={{color: "#005090", fontSize: "22px", fontFamily: "Proxima Nova Lt", textAlign: "start"}}>
                        2020 год
                    </h1>
                    <FileLeftLineKDN text={"Мастер-классы VII Всероссийского совещание КДН и ЗП"}
                                     refers={"/masterseven"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={"Площадка № 3 VII Всероссийского совещание КДН и ЗП"} refers={"/ploshad3"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={"Площадка № 2 VII Всероссийского совещание КДН и ЗП"} refers={"/ploshad2"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={"Площадка № 1 VII Всероссийского совещание КДН и ЗП"} refers={"/ploshad1"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={"Пленарное заседание VII Всероссийского совещания КДН и ЗП"}
                                     refers={"/plen2020"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={"Итоги VII Всероссийского совещания КДН и ЗП"} refers={"/kdnzip2020"}
                                     ico={arr}/>
                    <FileLeftLineKDN text={respData[55]?.description} refers={respData[55]?.file} ico={ico}/>
                    <FileLeftLineKDN text={respData[56]?.description} refers={respData[56]?.file} ico={ico}/>
                </div>
            </div>
            <div style={{marginTop: "-3px"}}>
                <HrefsKDN hrefsArr={hrefsArrKDN}/>
            </div>
        </div>

    </>
}
export default FilesKDNInt