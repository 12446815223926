import React from "react";
import {useState, useEffect} from "react";
import s from "./Main.module.css"
import arrow from "../../assets/arrow.svg"
import {ArrowRightOutlined} from "@ant-design/icons"
import news from "../../assets/img/news.png"
import news1 from "../../assets/img/news1.jpg"
import news2 from "../../assets/img/news2.png"
import banner1 from "../../assets/img/banner5.PNG"
import banner2 from "../../assets/img/banner2.png"
import banner3 from "../../assets/img/3.jpg"
import banner4 from "../../assets/img/banner4.png"
import banner6 from "../../assets/img/banner6.PNG"
import banner7 from "../../assets/img/banner7.jpg"
import bannerChildren from "../../assets/News/children2.gif"
import img1 from "../../assets/Directions/1_1.svg"
import img2 from "../../assets/Directions/1_2.svg"
import img3 from "../../assets/Directions/2_1.svg"
import img4 from "../../assets/Directions/2_2.svg"
import img5 from "../../assets/Directions/3_1.svg"
import img6 from "../../assets/Directions/3_2.svg"
import img7 from "../../assets/Directions/4_1.svg"
import img8 from "../../assets/Directions/4_2.svg"
import img9 from "../../assets/Directions/5_1.svg"
import img10 from "../../assets/Directions/5_2.svg"
import img11 from "../../assets/Directions/6_1.svg"
import img12 from "../../assets/Directions/6_2.svg"
import img13 from "../../assets/Directions/7_1.svg"
import img14 from "../../assets/Directions/7_2.svg"
import img15 from "../../assets/Directions/8_1.svg"
import img16 from "../../assets/Directions/8_2.svg"
import img17 from "../../assets/Directions/9_1.svg"
import img18 from "../../assets/Directions/9_2.svg"
import img19 from "../../assets/Directions/10_1.svg"
import img20 from "../../assets/Directions/10_2.svg"
import img21 from "../../assets/Directions/11_1.svg"
import img22 from "../../assets/Directions/11_2.svg"
import img23 from "../../assets/Directions/12_1.svg"
import img24 from "../../assets/Directions/12_2.svg"
import img25 from "../../assets/Directions/l2.svg"
import img26 from "../../assets/Directions/l1.svg"
import gl1 from "../../assets/img/gl1.jpg"
import gl2 from "../../assets/img/gl2.jpg"
import gl3 from "../../assets/img/gl3.png"
import pr1 from "../../assets/img/pr1.PNG"
import ch1 from "../../assets/img/ch1.PNG"
import bannerb from "../../assets/banner2.jpg"
import telega from "../../assets/telega.svg"
import help1 from "../../assets/Helpful/pol_res1.png"
import help2 from "../../assets/Helpful/polezn-resurs-2.png"
import help3 from "../../assets/Helpful/3.png"
import help4 from "../../assets/Helpful/4.png"
import help5 from "../../assets/Helpful/5.png"
import help6 from "../../assets/Helpful/6.png"
import people from "../../assets/SUVU/realise.PNG"
import victorina from "../../assets/Viktorina/20230518.jpg"
import axios from "axios"
import {NavLink} from "react-router-dom";
import Calendar from 'react-calendar';
import "./Calendar.css"


const Main = () => {
    const [newsData, setNews] = useState([])
    const [threeImg, setThreeImg] = useState([])
    const [newsNumber, setNewsNumber] = useState(0)

    useEffect(() => {
        const lastIndex = newsData.length - 1
        if (newsNumber < 0) {
            setNewsNumber(lastIndex)
        }
        if (newsNumber > lastIndex) {
            setNewsNumber(0)
        }
    }, [newsNumber, newsData, threeImg])

    useEffect(() => {
        const interval = setInterval(() => setNewsNumber(newsNumber + 1), 8000);
        axios.get("https://fcprc.ru/api/main").then(
            res => {
                let data = res.data.reverse()
                const threeNews = [data[0], data[1], data[2]]
                const threeImg = [data[0].img, data[1].img, data[2].img]
                setNews(threeNews)
                setThreeImg(threeImg)
            }
        )
        return () => clearInterval(interval)
    }, [newsNumber])

    return <>
        <div className={s.main}>
            <News newsData={newsData} newsNumber={newsNumber} imgs={threeImg}/>
        </div>
    </>
}

const News = ({newsData = [], newsNumber, imgs}) => {


    return <>

        <div className={s.news}>
            <div className={s.img}>
                <NavLink to={newsData[newsNumber % 3]?.ref}>
                    <img src={imgs[newsNumber % 3]}/>
                </NavLink>

            </div>
            <div className={s.description}>
                {newsData.map((el, i) => <>
                        <OneNews newsNumber={newsNumber} newNumber={i} head={el.Header} refs={el.ref} date={el.date}/>
                    </>
                )}

            </div>
            <div className={s.ImgCircles}>
                <div style={{border: newsNumber === "0" ? "black solid 2px" : "#eaf1f4solid 2px"}}>
                    <img src={imgs[0]}/>
                </div>
                <div>
                    <img src={imgs[1]}/>
                </div>
                <div>
                    <img src={imgs[2]}/>
                </div>
            </div>
            <div style={{marginTop: "30px", width: "330px"}}>
                <div className={s.allmer}>
                    <a href="/news">
                        Все новости<ArrowRightOutlined style={{color: "red"}}/>
                    </a>
                </div>

                <a href="https://pobeda.onf.ru/">
                    <img src={people} style={{width: "330px"}}/>
                </a>
            </div>
        </div>

        <Directions/>
        <div className={s.banners}>
            <div>
                <a href="http://молодежь-современной-россии.рф/">
                    <img src={banner6}/>
                </a>
            </div>
            <div>
                <a href="https://опрос-родителей-о-пав.рф/">
                    <img src={banner1}/>
                </a>
            </div>
            <div>
                <NavLink to="/responsible-behavior">
                    <img src={banner2}/>
                </NavLink>
            </div>
            {/*<div>*/}
            {/*    <a href="https://опрос-педагогов-о-вич.рф/">*/}
            {/*        <img src={banner3}/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            <div>
                <a href="/monitoringi">
                    <img src={banner4}/>
                </a>
            </div>
            <div>
                <a href="/rec_rules">
                    <img src={banner7}/>
                </a>
            </div>

        </div>


        <h2 style={{
            margin: "0 auto",
            fontSize: "28px",
            marginBottom: "30px",
            marginTop: "30px",
            textAlign: "left",
            fontFamily: "Segoe UI",
            width: "70%",
            fontWeight: "500",
            maxWidth: "1200px"
        }}>
            Горячие линии
        </h2>
        < Hotlines/>
        <h2 style={{
            margin: "0 auto",
            fontSize: "28px",
            marginBottom: "30px",
            marginTop: "30px",
            textAlign: "left",
            fontFamily: "Segoe UI",
            width: "73%",
            fontWeight: "500",
            maxWidth: "1200px"
        }}>
            Полезные ресурсы
        </h2>
        <Helpful/>
    </>
}
/*
<div className={s.calendarBlock}>
            <div>
                <Calendar defaultValue={[new Date(2023, 3, 4), new Date(2023, 3, 5),]}/>
            </div>
            <div style={{border: "1px solid gray", borderRadius: "5px", paddingTop: "20px", marginRight: "40px"}}>
                Январь 09
                <p>
                    Нет мероприятий
                </p>
            </div>
            <div style={{borderRadius: "5px", paddingTop: "20px"}}>
                Выбор категории
                <p style={{
                    borderRadius: "5px",
                    paddingTop: "10px",
                    textAlign: "start",
                    fontSize: "16px",
                    fontFamily: "Proxima Nova Rg"
                }}>
                    <div>
                        <input type="checkbox"/>Вебинар
                    </div>
                    <div>
                        <input type="checkbox"/>Выездной мониторинг

                    </div>
                    <div>
                        <input type="checkbox"/>Конкурс
                    </div>
                    <div>
                        <input type="checkbox"/>Конференция
                    </div>
                    <div>
                        <input type="checkbox"/>Круглый стол
                    </div>
                    <div>
                        <input type="checkbox"/>Окружное совещание
                    </div>
                    <div>
                        <input type="checkbox"/>Семинар
                    </div>
                    <div>
                        <input type="checkbox"/>Симпозиум
                    </div>

                </p>
            </div>
        </div>
        */
const Directions = () => {
    return <div className={s.directions}>
        <div>
            <h3 style={{
                margin: "0 auto",
                marginBottom: "28px",
                marginTop: "30px",
                textAlign: "left",
                fontFamily: "Segoe UI",
                width: "100%",
                fontWeight: "500",
                fontSize: "28px"
            }}>Ключевые направления</h3>
            <div>

                <div class={s.seven}>
                    <SevenBlock img1={img1} img2={img2} text="Десятилетие детства" href="https://10let.edu.gov.ru/"
                                blank={true}/>
                    <SevenBlock img1={img5} img2={img6} text="Международное сотрудничество"
                                href="/meshdunarodSotrudnichestvo"/>
                    <SevenBlock img1={img9} img2={img10} text="Профилактика девиантного поведения"
                                href="/profilaktika-deviantnogo-povedeniya/"/>
                    <SevenBlock img1={img26} img2={img25} text="Безопасность детства"
                                href="/bezopasnost-detstva"/>
                    <SevenBlock img1={img7} img2={img8} text="Медиация" href="/mediacia/"/>
                    <SevenBlock img1={img3} img2={img4} text="ФРЦ КДН и ЗП" href="/kdn"/>
                    <SevenBlock img1={img11} img2={img12} text="ФРЦ СУВУ" href="/suvu"/>
                </div>
                <div className={s.fore}>
                    <div></div>
                    <SevenBlock img1={img15} img2={img16} blank={true}
                                text="ФРЦ в сфере защиты прав детей, оставшихся без попечения родителей"
                                href="https://frcopeca.ru/"/>
                    {/*<SevenBlock img1={img17} img2={img18} blank={true} text="ФРЦ ПМПК" href="https://pmpkrf.ru/"/>*/}
                    <SevenBlock img1={img13} img2={img14} text="Всероссийские конкурсы" href="/vserosconc"/>
                    {/*<SevenBlock img1={img19} img2={img20} blank={true} text="ППМС Помощь" href="https://ovzrf.ru/"/>*/}
                    <SevenBlock img1={img24} img2={img23} blank={true}
                                text="Федеральный центр развития программ социализации подростков"
                                href="https://подростковыецентры.рф/"/>
                                <div></div>
                </div>
            </div>

        </div>

    </div>
}
const OneNews = ({newsNumber = 1, backgroundColor, newNumber, head, refs, date}) => {

    return <div className={s.newsBlock} style={{
        backgroundColor: newsNumber == newNumber ? "#f7dde5" : backgroundColor,
        color: newsNumber == "0" ? "#fff" : "black"
    }}>
        <div style={{paddingLeft: "10px"}}>
            <h3>
                <a href={refs} class="href">{head}</a></h3>
            <div>
                <span>{date}</span>
            </div>
        </div>
    </div>

}
const SevenBlock = ({href = "https://10letie.edu.gov.ru", text, img1, img2, blank = false, backS = false}) => {
    return <a href={href} className={s.dirBlock} target={blank ? "_blank" : "_self"}>
        <div style={{
            backgroundImage: `url(${img2}`,
            backgroundRepeat: "no-repeat",
            backgroundSize: backS ? backS : false
        }}>
            <img src={img1} className={s.colorImg}/>
        </div>
        <div style={{BackgroundImage: {img1}, backgroundRepeat: "no-repeat", backgroundSize: backS ? backS : false}}>
            <span style={{textDecoration: "none"}}>{text}</span>
        </div>

    </a>

}
const Hotlines = () => {
    return <div className={s.hotlines}>
        <Hotline imgSrc={gl1} text="По вопросам проведения социально-психологического тестирования обучающихся"
                 style1={{}} to={"/hotline1"}/>
        <Hotline imgSrc={gl2}
                 text="По вопросам реализации жилищных прав детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа"
                 to="/hotline2" style1={{width: "115px", height: "125px"}}/>
        <Hotline imgSrc={gl3}
                 text="По вопросам урегулирования конфликтов в школе, медиации и примирения в образовательных организациях"
                 to="/hotline3" style1={{height: "105px", width: "95px"}}/>
    </div>
}
const Hotline = ({imgSrc, text, style1 = {}, to}) => {
    return <div className={s.hotlineBlock}>
        <div className={s.hotline}>
            <div>
                <img src={imgSrc} style={style1}/>
            </div>
            <div className={s.hotlineText}>
                {text}
            </div>
        </div>
        <Button to={to}/>
    </div>
}
const Button = ({to}) => {
    return <div className={s.buttonHotl}>
        <NavLink to={to}>
            Задать вопрос на горячую линию <img style={{width: "20px"}} src={arrow}/>
        </NavLink>
    </div>
}
const Helpful = () => {
    return <div className={s.helpful}>
        <a href="http://deti.gov.ru/"><ImgHelp img={help1} width="100px"/></a>
        <a href="https://oprf.ru/"><ImgHelp img={help2} width="auto"/></a>
        <a href="https://fond-detyam.ru/"><ImgHelp img={help3}/></a>
        <a href="http://ol-journal.ru/"><ImgHelp img={help4}/></a>
        <a href="http://solnechnymir.ru/"><ImgHelp img={help5}/></a>
        <a href="http://fincult.info"> <ImgHelp img={help6}/></a>
    </div>
}
const ImgHelp = ({img, width = "auto"}) => {
    return <div className="">
        <img src={img} style={{width: width}}/>
    </div>
}
export default Main