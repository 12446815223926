import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News040624 (1).jpg"
import img1 from "../../assets/News/News040624 (2).jpg"
import img2 from "../../assets/News/News040624 (3).jpg"

const News040624 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сопровождение служб медиации в специальных учебно-воспитательных учреждениях в мае 2024 года
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            В мае 2024 года отделом медиации Центра защиты прав и интересов детей были проведены мероприятия по
            сопровождению работы служб медиации специальных учебно-воспитательных учреждений, подведомственных
            Минпросвещения России.
            <p></p>
            Мероприятия прошли в дистанционном формате 16 и 17 мая с Орловским СУВУ, Омским СУВУ, Абаканским СУВУ,
            Санкт-Петербургским СУВУ, а также 30 и 31 мая с Куртамышским СУВУ, Раифским СУВУ и Астраханским СУВУ. К
            занятиям присоединились обучающиеся и сотрудники из других СУВУ.
            <p></p>
            На консультации сотрудников служб медиации СУВУ сотрудниками Центра обсуждались текущее состояние служб, их
            достижения и планы на 2024 год.
            <p></p>
            Коллеги из СУВУ рассказали про практику проведения школ юного медиатора в их учреждениях и о преемственности
            между детьми, которые готовятся к выпуску и недавно поступившими обучающимися.
            <p></p>
            На интерактивных занятиях обучающиеся выступили соведущими, повторили принципы медиации и отработали
            медиативные технологии с другими ребятами в качестве способа разрешения конфликтов и конструктивного
            общения.
            <p></p>
            Дети активно дискутировали в ходе занятий. Структура занятий позволила создать условия для общения
            обучающихся разных полов.
            <p></p>
            Обучающиеся дали позитивную обратную связь о занятиях, поблагодарили организаторов и сотрудников служб
            медиации других СУВУ за подготовку мероприятий и предоставленную возможность принять в них участие.
            <p></p>
            Мероприятия проводили начальник отдела медиации Центра защиты прав и интересов детей Козлов Анатолий
            Андреевич и аналитик II категории отдела медиации Жодзишская Лидия Николаевна.
        </div>
        <div>
            <img src={img1}/>
        </div>
        <div>
            <img src={img2}/>
        </div>
        <ToNews/>
    </div>
}


export default News040624;